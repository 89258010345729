@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

// Remove Bootstrap imports
// @import "custom_bootstrap_vars";
// @import "~bootstrap/scss/bootstrap";

// project specific CSS goes here
// Custom font styles
@layer base {
  body {
    @apply font-sans font-normal bg-white text-gray-800 leading-relaxed;

    width: 100%;
    margin: 0;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    @apply font-serif font-bold text-gray-900 mt-8 mb-4;
  }

  h1 {
    @apply text-2xl border-b-2 border-gray-900 pb-2;
  }

  h2 {
    @apply text-xl;
  }

  h3 {
    @apply text-lg;
  }
}
///////////////
// Variables //
///////////////

// Alert colors

$white: #fff;
$mint-green: #d6e9c6;
$black: #000;
$pink: #f2dede;
$dark-pink: #eed3d7;
$red: #b94a48;

////////////
// Alerts //
////////////

// bootstrap alert CSS, translated to the django-standard levels of
// debug, info, success, warning, error

.alert-debug {
  background-color: $white;
  border-color: $mint-green;
  color: $black;
}

.alert-error {
  background-color: $pink;
  border-color: $dark-pink;
  color: $red;
}
